import React, { Fragment } from "react";
import git from "../assets/projects/GitHub.jpg";
import vr from "../assets/projects/VR-Universe.jpg";
import ip from "../assets/projects/IP.jpg";
import not from "../assets/projects/not.jpg";
import dots from "../assets/projects/dots.jpg";
import xkcd from "../assets/projects/xkcdFun.jpg";

function Work() {
  return (
    <Fragment>
      <div className="grid-2 my-2">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.github.com/NickLargey"
        >
          <img
            src={git}
            alt="github"
            style={{ width: "25rem", margin: "auto", display: "block" }}
          />
          <div className="all-center text-primary">My Github Page.</div>
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://nicklargey.github.io"
        >
          <img
            src={vr}
            alt="github"
            style={{
              width: "25rem",
              margin: "auto",
              display: "block",
              border: "2px solid white",
            }}
          />
          <div className="all-center text-primary">
            A browser based VR Universe
          </div>
        </a>
      </div>
      <div className="grid-2 my-2">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://xkcd-api-fun.netlify.com/"
        >
          <img
            src={xkcd}
            alt="xkcd"
            style={{ width: "25rem", margin: "auto", display: "block" }}
          />
          <div className="all-center text-primary">
            A react app built to get the latest or a random XKCD comic.
          </div>
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://nicklargey.github.io/ip"
        >
          <img
            src={ip}
            alt="github"
            style={{ width: "25rem", margin: "auto", display: "block" }}
          />
          <div className="all-center text-primary">
            A IP address geolocator written in VanillaJS
          </div>
        </a>
      </div>
      <div className="grid-2 my-2">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://twitter.com/notDarnielle"
        >
          <img
            src={not}
            alt="github"
            style={{ width: "25rem", margin: "auto", display: "block" }}
          />
          <div className="all-center text-primary">
            A twitter bot written in Python to generate Mountain Goats lyrics
            using Markov chains.
          </div>
        </a>

        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://nicklargey.github.io/evo/index.html"
        >
          <img
            src={dots}
            alt="DOTS: RELOADED!"
            style={{
              width: "25rem",
              height: "15rem",
              margin: "auto",
              display: "block",
              border: "2px solid white",
            }}
          />
          <div className="all-center text-primary">
            A Simple 2D platformer built for my friends and I to pick a movie to
            watch together.
          </div>
        </a>
      </div>
    </Fragment>
  );
}

export default Work;
