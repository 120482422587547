import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import AlertState from "./context/alert/AlertState";

import Navbar from "./components/layout/Navbar";
import Footer from "./components/layout/Footer";

import Home from "./components/pages/home";
import About from "./components/pages/about";
import Work from "./components/pages/work";
import Art from "./components/pages/art";

import "./App.css";

function App() {
  return (
    <AlertState>
      <Router>
        <div className="App">
          <Navbar />
          <div className="container">
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/about" component={About} />
              <Route exact path="/work" component={Work} />
              <Route exact path="/art" component={Art} />
            </Switch>
          </div>
          <Footer />
        </div>
      </Router>
    </AlertState>
  );
}

export default App;
