import React, { useContext } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Alert from "./Alert";

import AlertContext from "../../context/alert/alertContext";

const Navbar = ({ title }) => {
  const alertContext = useContext(AlertContext);

  return (
    <nav className="navbar bg-dark">
      <Link to="/" onClick={() => alertContext.setAlert("", "home")}>
        <h1>{title}</h1>
        <Alert />
      </Link>

      <ul>
        <li>
          <Link
            to="/about"
            onClick={() => alertContext.setAlert("About Me..", "about")}
          >
            ABOUT
          </Link>
        </li>
        <li>
          <Link
            to="/work"
            onClick={() =>
              alertContext.setAlert("Examples of my Web Work..", "work")
            }
          >
            WORK
          </Link>
        </li>
        {/* <li>
          <Link
            to="/blog"
            onClick={() =>
              alertContext.setAlert("Walkthroughs and processes..", "blog")
            }
          >
            BLOG
          </Link>
        </li> */}
        <li>
          <Link
            to="/art"
            onClick={() =>
              alertContext.setAlert("My Music and Photography..", "art")
            }
          >
            ART
          </Link>
        </li>
      </ul>
    </nav>
  );
};
Navbar.defaultProps = {
  title: "Nick Largey",
};

Navbar.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Navbar;
