import { SET_ALERT } from "../types";

export default (state, action) => {
  switch (action.type) {
    case SET_ALERT:
      return {
        page: action.payload.page,
        msg: action.payload.msg,
      };
    default:
      return state;
  }
};
