import React, { Fragment } from "react";
import Slider from "../layout/Slider";

import city from "../assets/cityPlanners.jpg";
import nick from "../assets/nick.jpg";

const Art = () => {
  return (
    <Fragment>
      <Slider />
      <h3 className="all-center lead my-3">Music</h3>
      <div className="grid-2">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://cityplanners.bandcamp.com/"
        >
          <img
            src={city}
            alt="City Planners"
            style={{ width: "25rem", margin: "auto", display: "block" }}
          />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://nicklargey.bandcamp.com/"
        >
          <img
            src={nick}
            alt="Nick Largey"
            style={{ width: "25rem", margin: "auto", display: "block" }}
          />
        </a>
      </div>
    </Fragment>
  );
};

export default Art;
