import React, { useReducer } from "react";
import AlertContext from "./alertContext";
import AlertReducer from "./alertReducer";
import { SET_ALERT } from "../types";

const AlertState = (props) => {
  const initialState = {
    page: "",
    msg: "",
  };

  const [state, dispatch] = useReducer(AlertReducer, initialState);

  // Set Alert
  const setAlert = (msg, page) => {
    dispatch({
      type: SET_ALERT,
      payload: { msg, page },
    });
  };

  return (
    <AlertContext.Provider
      value={{
        page: state.page,
        msg: state.msg,
        setAlert,
      }}
    >
      {props.children}
    </AlertContext.Provider>
  );
};

export default AlertState;
