import React, { Fragment } from "react";
import resume from "../assets/Largey, Nick - Resume.pdf";

const About = () => {
  return (
    <Fragment>
      <div className="full all-center">
        <p className="lead">
          I began programming casually in python while working as a general
          manager in the restaurant industry. After spending many years, many
          late nights and many long weeks putting out metephorical fires and
          feeling pretty stagnant, I decided I needed to change careers. I was
          looking for something more fulfilling, something that offered
          challenges I wasn't getting in my daily routine. I began taking
          programming more seriously and and developed a love for it.
        </p>
        <p className="lead all-center">
          Seeing how the world around me was evolving and trying to find what
          piqued my interest, I began learning JavaScript leading the way to web
          development. Learning with the continuous visual feedback presented
          was something I enjoyed. Since the internet and distributed computing
          are becoming more and more ingrained in our everyday lives, I think
          it's important to try to understand how it works in order to make
          educated decisions on everything from what to purchase to how it's
          effecting legislation around the globe.{" "}
        </p>
        <p className="lead all-center">
          My goal is to become a developer, ultimately working in VR/AR.
        </p>
        <div className="lead all-center">
          <p>Please take a look at</p>
          <a href={resume} target="_blank" rel="noopener noreferrer">
            My Resume
          </a>
        </div>
      </div>
    </Fragment>
  );
};
export default About;
