import behold from "../assets/Photos/behold.jpg";
import buzzy1 from "../assets/Photos/buzzy1.jpg";
import cactus from "../assets/Photos/cactus.jpg";
import captured from "../assets/Photos/captured.jpg";
import cba from "../assets/Photos/CBA1.jpg";
import colin from "../assets/Photos/colin.jpg";
import cops from "../assets/Photos/cops.jpg";
import dar2 from "../assets/Photos/dar2.jpg";
import darMoon from "../assets/Photos/darMoonCircle.jpg";
import darShadow from "../assets/Photos/darShadow.jpg";
import dump from "../assets/Photos/dumpBetty.jpg";
import erica from "../assets/Photos/ericaForest.jpg";
import fallsOf from "../assets/Photos/fallsof.jpg";
import feral2 from "../assets/Photos/feral2.jpg";
import feral6 from "../assets/Photos/feral6.jpg";
import feralGuit from "../assets/Photos/feralGuit.jpg";
import gregFox from "../assets/Photos/gregFox.jpg";
import grue21 from "../assets/Photos/grue21.jpg";
import imo from "../assets/Photos/imoGuit.jpg";
import imop from "../assets/Photos/imopDrums.jpg";
import kenny from "../assets/Photos/kenny.jpg";
import leggings from "../assets/Photos/leggings.jpg";
import ma from "../assets/Photos/ma.jpg";
import OT from "../assets/Photos/OT1.jpg";
import OTGit from "../assets/Photos/OTGuit.jpg";
import rz from "../assets/Photos/RZ1.jpg";
import sertaline from "../assets/Photos/sertaline.jpg";
import st from "../assets/Photos/stVitus.jpg";
import sunn from "../assets/Photos/sunnBW.jpg";
import sunnPurp from "../assets/Photos/sunnPurp.jpg";
import dogBand from "../assets/Photos/theDogBandPiano.jpg";
import tina from "../assets/Photos/tina.jpg";
import wayfarer from "../assets/Photos/wayfarer.jpg";
import yellow from "../assets/Photos/yellowEyes.jpg";
import yuka from "../assets/Photos/yuka.jpg";

export const images = [
  grue21,
  imo,
  imop,
  kenny,
  leggings,
  behold,
  buzzy1,
  cactus,
  captured,
  cba,
  colin,
  cops,
  dar2,
  darMoon,
  darShadow,
  dump,
  erica,
  fallsOf,
  feral2,
  feral6,
  feralGuit,
  gregFox,
  ma,
  OT,
  OTGit,
  rz,
  sertaline,
  st,
  sunn,
  sunnPurp,
  dogBand,
  tina,
  wayfarer,
  yellow,
  yuka,
];
