import React, { Fragment } from "react";
import main from "../assets/main.jpg";

const Home = () => (
  <Fragment>
    <div className="full">
      <img
        src={main}
        className="all-center"
        alt="cover"
        style={{
          objectFit: "scale-down",
          maxHeight: "30rem",
          maxWidth: "27rem",
          borderRadius: "75%",
        }}
      ></img>
      <p
        style={{
          textAlign: "center",
          fontSize: "32px",
          margin: "1rem",
          padding: "0",
        }}
      >
        Computer Science student seeking employment in the development world.
      </p>
    </div>
  </Fragment>
);
export default Home;
